
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import Vue from "vue";
import Component from "vue-class-component";
extend("required", {
  ...required,
  message: "This field is required",
});
extend("email", {
  ...email,
  message: "A valid email is required",
});
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class ForgotPassword extends Vue {
  defaultItem = {
    email: null,
  };
  editedItem = {
    email: null,
  };
  loading = false;
  message = null;
  /**
   * Watch
   */
  // watch: {
  //   $route: {
  //     handler: function (route) {
  //       const query = route.query;
  //       if (query) {
  //         this.redirect = query.redirect;
  //         this.otherQuery = this.getOtherQuery(query);
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
  /**
   * Methods
   */
  private reset() {
    this.loading = true;
    // axios
    //   .post("/password/email", this.editedItem)
    //   .then((response) => {
    //     this.loading = false;
    //     this.message = response.data.message;
    //   })
    //   .catch((error) => {
    //     setTimeout(() => {
    //       this.loading = false;
    //       this.$refs.form.setErrors(error.response.data.errors);
    //       /**
    //        * Set snackbar message
    //        */
    //       if (error.response.data.message) {
    //         this.$store.commit({
    //           color: "success",
    //           message: error.response.data.message,
    //           toggle: true,
    //           type: "flashAlert",
    //         });
    //         this.$store.commit({
    //           color: "success",
    //           message: error.response.data.message,
    //           toggle: true,
    //           type: "snackbar",
    //         });
    //       }
    //     }, 3000);
    //   });
  }
}
